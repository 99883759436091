<template>
	<div>
		<h2 class="font-semibold text-lg text-red-500">WORK IN PROGRESS</h2>

		<div class="md:flex md:items-center md:justify-between mb-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Anouncement Banners
				</h2>
			</div>
			<div class="mt-4 flex md:mt-0 md:ml-4">
				<RefreshTableButton @refreshTable="refreshTable" />
				<router-link
					:to="{ name: 'content-announcement-banners-create' }"
					class="relative ml-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
				>
					<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Add Anouncement Banner
				</router-link>
			</div>
		</div>

		<div class="flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<ag-grid-vue
						style="height: 725px"
						class="ag-theme-alpine"
						:grid-options="gridOptions"
						:column-defs="columnDefs"
						:default-col-def="defaultColDef"
						:row-data="rowData"
						:modules="modules"
						:pagination="true"
						:pagination-page-size="50"
						:enable-cell-text-selection="true"
						:ensure-dom-order="true"
					></ag-grid-vue>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import TableActions from '@/components/TableActions.vue'

import { PlusIcon } from '@heroicons/vue/solid'

export default {
	name: 'AnouncementBanners',
	components: {
		AgGridVue,
		PlusIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			columnDefs: null,
			rowData: null,
			defaultColDef: null,

			modules: [ClientSideRowModelModule],
		}
	},
	beforeMount() {
		this.gridOptions = {
			context: {
				componentParent: this,
			},
		}

		this.columnDefs = [
			// { field: "id", minWidth: 310 },
			{ field: 'name', flex: 1 },
			{
				headerName: 'Actions',
				width: 300,
				cellRendererFramework: TableActions,
				cellRendererParams: {
					editRoute: 'md-staff-departments-id',
					showEdit: true,
					newTab: true,
				},
			},
		]

		this.defaultColDef = {
			resizable: true,
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
		}
	},
	mounted() {
		this.gridApi = this.gridOptions.api
		this.fetchAnouncementBanners()
	},
	methods: {
		fetchAnouncementBanners(reload = false) {
			if (reload) {
				this.gridApi.showLoadingOverlay()
			}
			this.loaderShow()

			this.api.announcementBanners
				.all()
				.then(data => {
					this.rowData = data
				})
				.catch(error => {
					this.toast.error('Error fetching announcement banners:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
					if (reload) {
						this.gridApi.hideOverlay()
					}
				})
		},

		refreshTable() {
			this.fetchAnouncementBanners(true)
		},

		booleanFormatter(params) {
			return params.value == '0' ? 'No' : 'Yes'
		},
	},
}
</script>
